import React, {CSSProperties} from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Quotes from '../../images/quotes.svg';
import Placeholder from '../../images/avatar-placeholder.svg';

const containerStyle: CSSProperties = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  paddingBottom: 120,
  marginBottom: 122,
  maxWidth: 1170,
  margin: '0 auto',
  textAlign: 'center'
};

const labelStyle: CSSProperties = {
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 500,
  padding: '4px 10px',
  borderRadius: 3,
  display: 'inline-block',
  marginTop: 120,
  marginBottom: 10,
  letterSpacing: 2.1
};

const headerStyle: CSSProperties = {
  color: '#4f40ab',
  fontSize: 48,
  fontWeight: 500,
  maxWidth: 630,
  margin: '0 auto 65px',
  lineHeight: 1.45
};

const Testimonial = styled.div`
  flex-basis: 33%;
  border-radius: 24px;
  border: solid 1px #e6ecfb;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 39px 32px 32px;
  background: white;
  transition: box-shadow 0.1s linear, border-color 0.1s linear;

  &:not(:last-child) {
    margin-right: 30px;
  }
  
  &:hover {
    box-shadow: 10px 12px 48px 0 rgba(181, 187, 203, 0.21);
    border-color: white;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.9;
    letter-spacing: -0.3px;
    color: #54595a
  }

  .avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 20px;
  }
`;

export const TestimonialsDesktop = (props: {
  label: string;
  color: string;
  background: string;
  title: string;
  comments: Array<{
    text: string;
    name: string;
    job: string;
    avatar?: string;
    avatarAlt?: string;
    linkText: string;
    linkUrl: string;
  }>
}): JSX.Element => {
  return (
    <section className={'desktop-only'} style={containerStyle}>
      <h2 style={{
        ...labelStyle,
        color: props.color,
        backgroundColor: props.background
      }}>
        <ReactMarkdown>{props.label}</ReactMarkdown>
      </h2>
      <div style={headerStyle}>
        <ReactMarkdown>{props.title}</ReactMarkdown>
      </div>
      <div style={{display: 'flex'}}>
        {
          props.comments.map((comment, index) => (
            <Testimonial key={index}>
              <img style={{marginRight: 'auto', marginBottom: 23}} src={Quotes} alt=""/>
              <div style={{
                padding: '17px 19px',
                borderRadius: 14,
                backgroundColor: '#f6f7fb',
                flexGrow: 1,
              }}>
                <ReactMarkdown>{comment.text}</ReactMarkdown>
              </div>
              <div style={{
                borderWidth: 10,
                borderColor: '#f6f7fb transparent transparent transparent',
                height: 0,
                width: 0,
                borderStyle: 'solid',
                marginLeft: 18
              }}/>
              <div style={{display: 'flex'}}>
                {
                  comment.avatar
                    ? <img className="avatar" src={comment.avatar} alt={comment.avatarAlt}/>
                    : <img className="avatar" src={Placeholder} alt={'avatar'}/>
                }
                <div>
                  <div style={{color: '#54595a', fontSize: 18, fontWeight: 500, marginTop: 5}}>{comment.name}</div>
                  <span style={{fontSize: 14, color: '#808384'}}>{comment.job}, </span>
                  <a style={{fontSize: 14, color: '#4886ff'}} href={comment.linkUrl}>{comment.linkText}</a>
                </div>
              </div>
            </Testimonial>
          ))
        }
      </div>
    </section>
  );
};
