import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {MarkdownRemark} from '../models/markdown-remark';
import {PartnersList} from '../components/shared/partners';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {TitleSection} from '../components/stakeholder/title-section';
import {Testimonials} from '../components/stakeholder/testimonials';
import {BackgroundFigure} from '../components/shared/background-figure';
import {useState} from 'react';
import {RegularSectionModel} from '../models/regular-section-model';
import {RegularSection} from '../components/shared/regular-section';

interface StakeholderPageData extends BasePageData {
  stakeholderData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    heroImage: string;
    heroImageMobile: string;
    altText: string;
    description: string;
    inputPlaceholder: string;
    buttonText: string;
    headerList: Array<{listItem: string;}>
    subSections: Array<{
      type: 'testimonial';
      Label: string;
      color: string;
      background: string;
      title: string;
      testimonials: Array<{
        testimonial: {
          text: string;
          Name: string;
          jobTitle: string;
          link: string;
          linkText: string;
          profileImage?: string;
          altImage?: string;
        }
      }>
    } | RegularSectionModel>
  }>;
  partnerLogos: MarkdownRemark<{
    subSections: Array<{
      type: 'partners';
      sectionTitle: string;
      partnerLogos: Array<{
        altText: string;
        logo: string;
      }>
    }>
  }>
}

const StakeholderPage = ({data}: {data: StakeholderPageData}): JSX.Element => {

  const logosData = data.partnerLogos.edges[0].node.frontmatter.subSections.find(s => s.type === 'partners');

  const [isFormVisible, setFormVisibility] = useState(false);
  const [contactEmail, setContactEmail] = useState<string | undefined>(undefined);

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.stakeholderData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        isFormVisible={isFormVisible}
        email={contactEmail}
        onFormClose={() => setFormVisibility(false)}
      />
      <TitleSection
        title={data.stakeholderData.edges[0].node.frontmatter.title}
        altText={data.stakeholderData.edges[0].node.frontmatter.altText}
        subTitle={data.stakeholderData.edges[0].node.frontmatter.description}
        heroImage={data.stakeholderData.edges[0].node.frontmatter.heroImage}
        heroImageMobile={data.stakeholderData.edges[0].node.frontmatter.heroImageMobile}
        inputPlaceholder={data.stakeholderData.edges[0].node.frontmatter.inputPlaceholder}
        buttonText={data.stakeholderData.edges[0].node.frontmatter.buttonText}
        listItems={data.stakeholderData.edges[0].node.frontmatter.headerList.map(i => i.listItem)}
        onSubmit={email => {setFormVisibility(true); setContactEmail(email);}}
      />
      <PartnersList logos={logosData?.partnerLogos ?? []} title={logosData?.sectionTitle ?? ''}/>
      {
        data.stakeholderData.edges[0].node.frontmatter.subSections
          .map((s, index) => {
            switch (s.type) {
              case 'regular':  {
                return (
                  <RegularSection key={index} {...s.section} />
                );
              }
              case 'testimonial': {
                return (
                  <Testimonials
                    label={s.Label}
                    color={s.color}
                    background={s.background}
                    title={s.title}
                    key={index}
                    comments={
                      s.testimonials
                        ?.map(t => t.testimonial)
                        .map(t => ({
                          text: t.text,
                          name: t.Name,
                          job: t.jobTitle,
                          avatar: t.profileImage,
                          avatarAlt: t.altImage,
                          linkText: t.linkText,
                          linkUrl: t.link,
                        })) ?? []
                    }
                  />
                );
              }
              default:
                return '';
            }
          })
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 1210}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 18, height: 36}}
          position={{right: 0, top: 1130}}
          style={{
            zIndex: 0,
            borderRadius: 18,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <div style={{
          position: 'absolute',
          left: -10,
          top: 1900,
          zIndex: 1,
          width: 116,
          height: 155,
          overflow: 'hidden',
          transform: 'rotate(113deg)',
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#eaedfd'}
            size={{width: 80, height: 80}}
            position={{right: 20, bottom: -10}}
          />
        </div>
        <div style={{
          position: 'absolute',
          left: -10,
          bottom: 1000,
          zIndex: -1,
          width: 116,
          height: 155,
          overflow: 'hidden',
          transform: 'rotate(113deg)',
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#ffc02a'}
            size={{width: 80, height: 80}}
            position={{right: 20, bottom: -10}}
          />
        </div>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 77, height: 203}}
          position={{top: 2612, right: 0}}
        />
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 77, height: 203}}
          position={{bottom: 1240, right: 0}}
        />
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default StakeholderPage;

export const pageQuery = graphql`
  {
    ...BaseData
    stakeholderData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/stakeholder.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            heroImage
            heroImageMobile
            altText
            description
            inputPlaceholder
            buttonText
            headerList {
              listItem
            }
            subSections {
              type
              Label
              color
              background
              title
              testimonials {
                testimonial {
                  text
                  Name
                  jobTitle
                  link
                  linkText
                  profileImage
                  altImage
                }
              }
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
            }
          }
        }
      }
    }
    partnerLogos: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/home.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            subSections {
              type
              sectionTitle
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
              partnerLogos {
                altText
                logo
              }
            }
          }
        }
      }
    }
  }
`;
