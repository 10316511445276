import React from 'react';
import {TestimonialsDesktop} from './testimonials-desktop';
import {TestimonialsMobile} from './testimonials-mobile';

export const Testimonials = (props: {
  label: string;
  color: string;
  background: string;
  title: string;
  comments: Array<{
    text: string;
    name: string;
    job: string;
    avatar?: string;
    avatarAlt?: string;
    linkText: string;
    linkUrl: string;
  }>
}): JSX.Element => {

  const isSSR = typeof window === 'undefined';

  return (
    <React.Fragment>
      <TestimonialsDesktop {...props}/>
      {!isSSR && <TestimonialsMobile {...props} />}
    </React.Fragment>
  );
};
