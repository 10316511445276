import React from 'react';
import Background from '../../images/stakeholder-background.svg';
import styled from 'styled-components';
import {device} from '../../models/devices';
import ReactMarkdown from 'react-markdown';
import {EmailForm} from '../shared/email-form';

const Container = styled.section`
  padding: 184px 24px 0;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: bottom center;
  text-align: center;
  margin-bottom: 123px;
  background-size: cover;
  
  @media screen and ${device.tablet} {
    padding-top: 115px;
    margin-bottom: 16px;
    background-size: auto 1270px;
    background-position: top center;
  }
`;

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 48px;
  line-height: 1.38;
  max-width: 1000px;
  margin: 0 auto 19px;
  
  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5;
    margin-bottom: 37px;
    text-align: left;
  }
`;

const SubTitle = styled.div`
  margin: 0 auto 37px;
  max-width: 800px;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #cfe7fc;
  mix-blend-mode: normal;
  opacity: 0.81;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 30px;
    text-align: left;
    max-width: initial;
  }
`;

const MainImage = styled.img`
  
  max-width: 1171px;
  width: 100%;

  &.mobile-only {
    max-width: 500px;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
  
  @media screen and ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 4px;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-right: 16px;

  p {
    display: inline-block
  }

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin-right: 12px;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    background: radial-gradient(circle,
    rgba(0, 197, 245, 1) 0%,
    rgba(0, 197, 245, 1) 35%,
    rgba(0, 197, 245, 0.34) 35%,
    rgba(0, 197, 245, 0.34) 100%);
  }

  @media screen and ${device.tablet} {
    display: flex;
    align-items: baseline;
    margin: 0 0 24px;
  }
`;

export const TitleSection = (props: {
  title: string;
  altText: string;
  subTitle: string;
  heroImage: string;
  heroImageMobile: string;
  inputPlaceholder: string;
  buttonText: string;
  listItems: string[];
  onSubmit?: (email: string) => void;
}): JSX.Element => {
  return (
    <Container>
      <Title><ReactMarkdown>{props.title}</ReactMarkdown></Title>
      <SubTitle><ReactMarkdown>{props.subTitle}</ReactMarkdown></SubTitle>
      <List>
        {
          props.listItems.map((text, index) => (
            <ListItem key={index}>
              <ReactMarkdown>{text}</ReactMarkdown>
            </ListItem>
          ))
        }
      </List>
      <div style={{marginBottom: 64}}>
        <EmailForm
          inputPlaceholder={props.inputPlaceholder}
          buttonText={props.buttonText}
          buttonColor={'green'}
          inputWidth={296}
          onSubmit={email => props.onSubmit?.(email)}
        />
      </div>
      <MainImage
        className={'desktop-only'}
        src={props.heroImage}
        alt={props.altText}
      />
      <MainImage
        className={'mobile-only'}
        src={props.heroImageMobile}
        alt={props.altText}
      />
    </Container>
  );
};
