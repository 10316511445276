import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Quotes from '../../images/quotes.svg';
import Placeholder from '../../images/avatar-placeholder.svg';
import {Arrows} from '../shared/arrows';
import Slider from 'infinite-react-carousel';

const Label = styled.h2`
  color: #47b359;
  text-transform: uppercase;
  background-color: rgba(113, 192, 85, 0.32);
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 3px;
  display: inline-block;
  margin: 0;
`;

const Header = styled.span`
  font-weight: 500;
  color: #4f40ab;
  display: block;
  font-size: 24px;
  line-height: 1.5em;
  margin: 7px 0 24px;
`;

const Testimonial = styled.div`
  box-shadow: 10px 12px 48px rgba(181, 187, 203, 0.205829);
  border-radius: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 24px 32px;
  transition: box-shadow 0.1s linear, border-color 0.1s linear;
  box-sizing: border-box;
  width: calc(100% - 48px);
  margin: 0 24px 80px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    box-shadow: 10px 12px 48px 0 rgba(181, 187, 203, 0.21);
    border-color: white;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: #54595a
  }

  .avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 20px;
  }
`;

const HeaderContainer = styled.div`
  text-align: left;
  padding: 0 24px;
  margin-bottom: 32px;
  margin-top: 80px;
`;

const SliderContainer = styled.div`
  flex: 1;
  overflow: hidden;

  .carousel-item {
    height: auto;
  }
`;

export const TestimonialsMobile = (props: {
  label: string;
  color: string;
  background: string;
  title: string;
  comments: Array<{
    text: string;
    name: string;
    job: string;
    avatar?: string;
    avatarAlt?: string;
    linkText: string;
    linkUrl: string;
  }>
}): JSX.Element => {

  const sliderRef = React.createRef<{
    slickNext: () => void;
    slickPrev: () => void;
    slickGoTo: () => void;
    slickPause: () => void;
    slickPlay: () => void;
  }>();

  const changeSlide = (offset: number) => {
    // setActiveIndex(activeIndex + offset);
    if (offset > 0) {
      sliderRef.current?.slickNext();
    } else {
      sliderRef.current?.slickPrev();
    }
  };

  return (
    <section className={'mobile-only'}>
      <HeaderContainer>
        <Label><ReactMarkdown>{props.label}</ReactMarkdown></Label>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Arrows onLeft={() => changeSlide(-1)} onRight={() => changeSlide(+1)} />
      </HeaderContainer>
      <SliderContainer>
        <Slider
          ref={sliderRef}
          slidesToShow={1}
          arrows={false}
          virtualList={true}
          adaptiveHeight={true}
        >
          {
            props.comments.map((comment, index) => (
              <div key={index}>
                <Testimonial>
                  <img style={{marginRight: 'auto', marginBottom: 23}} src={Quotes} alt=""/>
                  <div style={{
                    padding: '20px 20px 32px',
                    borderRadius: 12,
                    backgroundColor: '#f6f7fb',
                    flexGrow: 1,
                  }}>
                    <ReactMarkdown>{comment.text}</ReactMarkdown>
                  </div>
                  <div style={{
                    borderWidth: 10,
                    borderColor: '#f6f7fb transparent transparent transparent',
                    height: 0,
                    width: 0,
                    borderStyle: 'solid',
                    marginLeft: 18
                  }}/>
                  <div style={{display: 'flex'}}>
                    {
                      comment.avatar
                        ? <img className="avatar" src={comment.avatar} alt={comment.avatarAlt}/>
                        : <img className="avatar" src={Placeholder} alt={'avatar'}/>
                    }
                    <div>
                      <div style={{color: '#54595a', fontSize: 18, fontWeight: 500, marginTop: 5}}>{comment.name}</div>
                      <span style={{fontSize: 14, color: '#808384'}}>{comment.job}, </span>
                      <a style={{fontSize: 14, color: '#4886ff'}} href={comment.linkUrl}>{comment.linkText}</a>
                    </div>
                  </div>
                </Testimonial>
              </div>
            ))
          }
        </Slider>
      </SliderContainer>
    </section>
  );
};
